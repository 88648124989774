import React, { useState, useEffect, Component, useRef } from 'react';
import { ActivityIndicator, SafeAreaView, Animated, StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, Modal, ImageBackground, Pressable} from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { Drawer, Button,MD3LightTheme as DefaultTheme, IconButton,DataTable, List} from 'react-native-paper';

const ModalActivity =  ({ isVisible }) => { 

return(
  
  <Modal
animationType="fade"
transparent={true}
visible={isVisible}
onRequestClose={() => {
Alert.alert("Modal has been closed.");
}}
>
<Pressable style={styles.centeredView} >
<Pressable style={styles.modalView}>
<ActivityIndicator size="large" color='#3F78E0' />
</Pressable>
</Pressable>
</Modal>
);

}

const styles = StyleSheet.create({
    
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 0,
    backgroundColor: 'rgba(255,255,255,0.5)'
  },
  modalView: {
    margin: 30,
    borderRadius: 5,
    padding: 45,
   },
  textStyle: {
  color: 'white',
  fontWeight: "bold",
  textAlign: "right",
  fontSize: 20
   },
   textStyle1: {
    color: '#00808B',
    fontWeight: "bold",
    textAlign: "right",
    marginHorizontal:10,
    fontSize: 24
     },
   modalText: {
  fontSize:16,
 color:'white',
 fontWeight:'bold'
   },
   modalTextbold: {
    fontWeight:'bold'
    },
    modalVText: {
      paddingBottom:20,
      alignItems: "center"
       }

  });

  export default ModalActivity;